<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Renovacion')}}</h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="poliza" v-model:expandedRows="expandedRows" class="p-datatable-customers" dataKey="id" @row-expand="onRowExpand" @row-collapse="onRowCollapse">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="buscar" type="number"/>
                            </span>
                            <Button :label="$t('Buscar')" icon="pi pi-search" class="p-button-secondary" @click="Buscar"/>
                        </div>
                    </template>
					<Column :expander="true" headerStyle="width: 3rem" />
                    <Column :header="$t('Registro')" field="fecha" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.fecha}}                              
                        </template>
                    </Column>  
                    <Column :header="$t('Venta')" field="fpago" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.fpago}}                              
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Número Póliza')" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Customer')+' #'">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                            
                        </template>
                    </Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
                            <DataTable :value="slotProps.data.evaluacion" :scrollable="true" v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                                <Column :header="$t('Renovación')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{$t(slotProps.data.renovacion)}}                                    
                                    </template>
                                </Column>
                                <Column :header="$t('Prima Bruta WA')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.primaini*slotProps.data.duracion)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Recargo Años del Vehículo')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.recarga_veh*slotProps.data.duracion)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Recargo Edad Persona')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.recarga_edad*slotProps.data.duracion)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Recargo Volante')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.recarga_volante*slotProps.data.duracion)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Total WA')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.totalwa*slotProps.data.duracion)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Sin reclamación')" headerStyle="width: 180px">sss
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.totalwa*slotProps.data.noclaim*slotProps.data.duracion)}}
                                </template>
                                </Column>
                                <Column :header="$t('Cobertura Adicional')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.monto*0)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Forma de pago')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        <div v-if="slotProps.data.efectivo>0">
                                            Cash
                                        </div>
                                        <div v-else>
                                            No Cash
                                        </div>
                                    </template>
                                </Column>
                                <Column :header="$t('Descuento Especial')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.totalwa*slotProps.data.duracion*slotProps.data.valor)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Prima WA')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.primabrutat*slotProps.data.duracion)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Costo')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.kosten)}}
                                    </template>
                                </Column>
                                <Column :header="$t('OB')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.ob)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Cuotas')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{slotProps.data.cuotas}}
                                    </template>
                                </Column>
                                <Column :header="$t('Cargo Cuota')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.primabrutat*slotProps.data.porcentaje/100)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Prima Total')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.monto)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Sabee')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.monto-slotProps.data.broker_monto)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Broker')" headerStyle="width: 180px">
                                    <template #body="slotProps">
                                        {{moneda(slotProps.data.broker_monto)}}
                                    </template>
                                </Column>
                            </DataTable>

						</div>
					</template>
				</DataTable>

			</div>
            <div class="p-col-6" v-if="historico.length>0">
                <div class="card">
                    <DataTable :value="historico" class="p-datatable-sm" dataKey="id">
                        <Column header="#">
                            <template #body="slotProps">
                                {{ slotProps.index }}
                            </template>
                        </Column>
                        <Column field="activacion" :header="$t('Activación')">
                            <template #body="slotProps">
                                {{convfech(slotProps.data.activacion)}}
                            </template>
                        </Column>
                        <Column field="vence" :header="$t('Vencimiento')">
                            <template #body="slotProps">
                                {{convfech(slotProps.data.vence)}}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import XLS from "../service/XLS";

export default {
	data() {
		return {
            expandedRows: [],
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
            police: {},
            duracion: [],
            cuotas: [],
            buscar: null,
            minimo: null,
            maximo: null,
            monto: 0,
            pagos: 0,
            deudas: 0,
            efectivo: 0,
            iss: 0,
            broker: 0,
            historico: [],
        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
	},
	mounted() {
	},
	methods: {
		VerXLS(){
            const xls = new XLS();
            xls.Reporte11(this.poliza);
		},
        Buscar(){
            if(this.buscar){
                this.$store.commit('Loading');
                this.poliza = [];
                const Consulta = new API('Reportes');
                Consulta.Procesar('Report11',{
                    caso: 	'Buscar',
                    buscar: this.buscar,
                    }).then(response => {
                        //this.$store.state.error = response;
                        if (response.result.poliza){
                            this.poliza = response.result.poliza;
                            this.historico = response.result.historico;
                        }
                        this.$store.commit('Loading');
                    });
            }
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},



	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
